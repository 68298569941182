import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export const DEFAULT_ATTENDEE = { attending: 'in person', meal: 'normal' };

export function isIOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function cssBackgroundHack() {
  const cssText = `
      .fixed-background {
        background-attachment: local !important;
      }

      .fixed-background:after {
        background-attachment: local !important;
      }
      `;
  const style = document.createElement('style');

  style.type = 'text/css';
  style.appendChild(document.createTextNode(cssText));
  document.head.appendChild(style);
}

let id = 0;
export function useUniqueId(name = 'layout-') {
  return useRef(`${name}${++id}`).current;
}

export function Portal({ children }) {
  const [container] = useState(() => {
    if (typeof window === 'undefined') return;
    let root = document.getElementById('modal-root');
    if (!root) {
      root = document.createElement('div');
      root.id = 'modal-root';
      document.body.appendChild(root);
    }
    let container = document.createElement('div');
    root.appendChild(container);
    return container;
  });

  useEffect(() => {
    return () => {
      container?.remove();
    };
  }, []);

  return createPortal(children, container);
}

export function titleCase(str) {
  return str
    .split(' ')
    .map(word => word[0].toUpperCase() + word.substr(1))
    .join(' ');
}

export async function hashPassword(message) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hash = await crypto.subtle.digest('SHA-512', data);
  return btoa(String.fromCharCode(...new Uint8Array(hash)));
}

export function incomingTransform({ fields, id, ...rest }) {
  return {
    id,
    name: fields.Name,
    meal: fields.mealType,
    restrictions: fields.dietaryRestrictions,
    attending: fields.attending,
    notes: fields.notes,
  };
}

export function keyLabel(key) {
  let spaced = key.replace(/([A-Z])/g, ` $1`);
  return spaced[0].toUpperCase() + spaced.slice(1);
}