import { nanoid } from 'nanoid';
import { base } from './backend';
import { hashPassword, incomingTransform } from './utils';

export async function getSiteConfig() {
  return base('site config')
    .select({
      filterByFormula: `category = "data"`,
    })
    .firstPage()
    .then(records => {
      let entries = records
        .map(record => record.fields)
        .map(record => [record.key, record[record.type]])
        .map(([key, value]) => {
          if (key === 'facebookEmbedCode') value = /src=\"(((?!=\").)*)\"/.exec(value)?.[1];
          return [key, value];
        });

      return Object.fromEntries(entries);
    });
}

export function getConfigRecords() {
  return base('site config').select({ filterByFormula: `category = "data"` }).firstPage();
}

export function updateConfigRecord(id, type, value) {
  return base('site config').update([{ id, fields: { [type]: value } }]);
}

export async function login({ username, password }) {
  let hashedPassword = await hashPassword(password);
  return base('site config')
    .select({
      filterByFormula: `AND({category} = "login", AND(LOWER({key}) = LOWER("${username}"), {text} = "${hashedPassword}"))`,
      fields: ['key', 'category', 'type'],
    })
    .firstPage()
    .then(r => {
      if (r.length < 1) throw new Error(`username and password don't match`);
      return r[0];
    });
}

export function searchUser(email: string) {
  if (!email.length) return Promise.reject('No results found');
  return base('invitees')
    .select({
      filterByFormula: `LOWER({email address}) = LOWER("${email}")`,
      fields: ['count', 'Name'],
    })
    .firstPage()
    .then(r => {
      if (r.length < 1) throw new Error('No results found');
      return {
        email,
        ...(r[0]?.fields || {}),
      };
    })
    .then(fields =>
      base('rsvp respondents')
        .select({
          filterByFormula: `LOWER({managingEmail}) = LOWER("${fields.email}")`,
          sort: [{ field: 'accountHolder', direction: 'desc' }],
        })
        .firstPage()
        .then(guests => {
          return {
            ...fields,
            guests: guests.map(guest => incomingTransform(guest)),
          };
        })
    );
}

export function createRsvpData(data) {
  let { notes = '', Name, guests = [{}], attending, email } = data;
  if (!guests.length) guests = [{}];

  let records = guests.map(({ name, meal, restrictions, attending, id, notes }) => ({
    id,
    fields: {
      Name: name,
      mealType: meal,
      dietaryRestrictions: restrictions || '',
      managingEmail: email,
      attending: data.attending || attending,
      notes,
    },
  }));

  records[0].fields.attending = records[0].fields.attending || attending;
  records[0].fields.Name = records[0].fields?.Name || Name;
  records[0].fields.notes = records[0].fields.notes || notes;
  records[0].fields.accountHolder = true;

  let updates = [];
  let creates = [];
  records.forEach(record => (record.id ? updates : creates).push(record));

  return Promise.all([
    updates.length && base('rsvp respondents').update(updates),
    creates.length && base('rsvp respondents').create(creates),
    sendRsvpEmail(records),
  ]);
}

function sendEmail(to, templateId, data) {
  return fetch(`/api/sendemail`, {
    method: 'post',
    body: JSON.stringify({
      to: to.trim(),
      templateId,
      data,
    }),
  }).then(r => {
    if (!r.ok) throw r;
    return r;
  });
}

function sendRsvpEmail(records) {
  let data = {
    guests: records.map(record => record.fields),
    email: encodeURIComponent(records[0].fields.managingEmail),
    hasLivestream: records.some(record => record.fields.attending === 'remotely'),
    isNew: !!records[0].id,
  };
  return sendEmail(records[0].fields.managingEmail, 'd-6a0f0c1ea5914051ab8895d1a0596212', data);
}

export function sendRehearsalEmail({ email, name }) {
  return sendEmail(email, 'd-3083e53670514c2caea53bbd9ed47d85', { email, name });
}

export async function getSharePosts(accum) {
  let posts = [];
  base('posts')
    .select()
    .eachPage(pagePosts => {
      posts.push(
        ...pagePosts.map(({ fields }) => ({ ...fields, images: JSON.parse(fields.images) }))
      );
      posts = posts.sort((a, b) =>
        a.postedAt < b.postedAt ? 1 : a.postedAt > b.postedAt ? -1 : 0
      );
      accum(posts);
    });
}

export async function createSharePost({ email, name, message, images = [] }) {
  return base('posts')
    .create([
      {
        fields: {
          id: nanoid(),
          email,
          postedAt: new Date().toISOString(),
          name,
          message,
          images: JSON.stringify(images),
        },
      },
    ])
    .then(post => post[0]);
}

const LIVESTREAM_TEMPLATE = 'd-352b00820842470592a9c81277d403a5';
const LIVESTREAM_IN_PERSON_TEMPLATE = 'd-904c93fd32db4e16ae683ec97c45f789';

export async function sendLivestreamEmails(setMessage) {
  base('rsvp respondents')
    .select()
    .all()
    .then(records =>
      records
        .map(record => record.fields)
        .filter(record => record.accountHolder && record.attending !== 'no')
        .reduce((accum, next) => {
          accum[next.attending] = accum[next.attending] || [];
          accum[next.attending].push(next);
          return accum;
        }, {})
    )
    .then(groups => {
      let inPersonList = groups['in person'];
      let remoteList = groups['remotely'];
      let total = [inPersonList, remoteList].flat().length;
      let sent = 0;
      const onSuccess = () => setMessage(`Sent ${++sent}/${total} emails`);
      const onFail = guest => e => console.error(guest, e);

      let testList = [inPersonList.find(p => p.managingEmail === 'adamsparks92@gmail.com')];

      const cleanData = guest => ({
        ...guest,
        managingEmail: encodeURIComponent(guest.managingEmail.trim()),
        Name: guest.Name.trim(),
      });

      return Promise.all(
        [
          remoteList.map(guest =>
            sendEmail(guest.managingEmail, LIVESTREAM_TEMPLATE, { guest: cleanData(guest) })
              .then(onSuccess)
              .catch(onFail(guest))
          ),
          inPersonList.map(guest =>
            sendEmail(guest.managingEmail, LIVESTREAM_IN_PERSON_TEMPLATE, {
              guest: cleanData(guest),
            })
              .then(onSuccess)
              .catch(onFail(guest))
          ),
        ].flat()
      );
    });
}
